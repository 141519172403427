import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';

import Section from 'molecules/Section/Section';

import ComponentConversionPanel from 'components/ConversionPanel/ConversionPanel';

type ConversionPanelProps = {
  id?: string;
};

const ConversionPanel: FC<ConversionPanelProps> = ({ id }) => {
  const { conversionPanel } = useStaticQuery(graphql`
    query getConversionPanel {
      conversionPanel: contentfulComponentConversionPanel(contentful_id: { eq: "5I4iBj0TIvWmjCELmHVbp7" }) {
        ...contentfulComponentConversionPanel
      }
    }
  `);

  return (
    <Section id={id}>
      <ComponentConversionPanel backgroundIsDark={false} {...conversionPanel} />
    </Section>
  );
};

export default ConversionPanel;
