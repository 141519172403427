import {
  ContentfulAsset,
  ContentfulComponentForm,
  ContentfulComponentVideo,
  ContentfulTemplateBlogBody,
  ContentfulTemplateResourceBody,
} from 'graphqlTypes';
import { FC } from 'react';

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import dotsImg from 'assets/images/dots.svg';

import { media } from 'atoms/breakpoints/breakpoints';
import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image, { ImageData } from 'quarks/Image';
import Text from 'quarks/Text';

import Breadcrumbs, { BreadcrumbsProps } from 'components/Breadcrumbs/Breadcrumbs';
import ComponentButton from 'components/Button/ComponentButton';
import ComponentVideo from 'components/Video/Video';

import { formatDate } from 'utils/functions';
import timeToRead from 'utils/timeToRead';

type HeroColumnProps = BreadcrumbsProps & {
  displayBreadcrumbs?: boolean;
  featuredImage?: ContentfulAsset | null;
  pdf?: ContentfulAsset | null;
  title?: string | null;
  cid?: string | null;
  category?: string | null;
  publishDate?: Date | null;
  author?: any | null;
  body?: ContentfulTemplateResourceBody | ContentfulTemplateBlogBody | null;
  video?: ContentfulComponentVideo | null;
};

const HeroColumn: FC<HeroColumnProps> = ({
  author,
  body,
  breadcrumbs,
  category,
  cid,
  displayBreadcrumbs,
  featuredImage,
  pdf,
  publishDate,
  title,
  video,
}) => (
  <Flex
    position="relative"
    flexDirection="column"
    xs={{ paddingTop: 0 }}
    sm={{ paddingTop: 48 }}
    paddingBottom={48}
    backgroundColor="navy-900"
    css={`
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 20%;
        background-color: ${video?.videoUrl ? color.gray[50] : color.common.white};
        ${media.md} {
          height: 30%;
        }
      }
    `}
  >
    <Flex
      width="100%"
      maxWidth="1320px"
      marginX="auto"
      paddingX={20}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      gap="56px"
      position="relative"
      zIndex="1"
      md={{ gap: '64px' }}
    >
      {displayBreadcrumbs !== false && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Flex flexDirection="column" justifyContent="center" alignItems="center" width="100%" maxWidth="970px" gap="24px">
        {category && (
          <Text
            borderRadius="16px"
            paddingY={4}
            paddingX={12}
            textStyle="textXs"
            fontWeight="semiBold"
            textColor="green-900"
            textTransform="capitalize"
            width="fit-content"
            backgroundColor="green-50"
            border="1px solid"
            borderColor="gray-200"
          >
            {category}
          </Text>
        )}
        {title && (
          <Heading
            as="h1"
            textAlign="center"
            fontSize="h4"
            lineHeight="h4"
            textColor="common-white"
            fontFamily="secondaryFont"
            sm={{ fontSize: 'h3', lineHeight: 'h3' }}
            lg={{ fontSize: 'h2', lineHeight: 'h2' }}
          >
            {title}
          </Heading>
        )}
        {pdf && (
          <ComponentButton
            label="Download PDF"
            popup={
              {
                __typename: 'ContentfulComponentForm',
                formType: 'Download PDF',
                heading: 'For insights, tips, and more valuable resources, please complete this form.',
                assetData: pdf,
                hubspotForm: { guid: '8a197c87-a24d-4485-8751-0292838c3e7e' },
                cid,
              } as unknown as ContentfulComponentForm
            }
          />
        )}
        {publishDate && body && (
          <Flex
            width="100%"
            marginTop={8}
            gap="16px"
            alignItems="normal"
            justifyContent="space-evenly"
            sm={{ gap: '32px', maxWidth: '500px', alignItems: 'center' }}
          >
            {author?.name && (
              <Flex flex={1} display="flex" justifyContent="center">
                <Text textStyle="textXs" fontWeight="regular" textColor="gray-50">
                  {author.name}
                </Text>
              </Flex>
            )}
            {publishDate && (
              <Flex flex={1} display="flex" justifyContent="center">
                <Text textStyle="textXs" textColor="gray-50" fontWeight="regular">
                  {formatDate(publishDate, true)}
                </Text>
              </Flex>
            )}
            {body && (
              <Flex flex={1} justifyContent="center" display="none" sm={{ display: 'flex' }}>
                <Text fontWeight="regular" textColor="gray-50" textStyle="textXs">
                  {timeToRead(body)} min read
                </Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      {video && (
        <Container
          position="relative"
          width="100%"
          maxWidth="600px"
          marginX="auto"
          borderRadius="6px"
          overflowX="hidden"
          overflowY="hidden"
          onClick={() => setIsPlay(true)}
          css={{
            '&& .lty-playbtn': {
              display: 'none',
            },
          }}
        >
          <ComponentVideo {...video} />
        </Container>
      )}
      {!video && featuredImage && (
        <Flex width="100%" maxWidth="770px" marginX="auto">
          <Image
            image={featuredImage as ImageData}
            alt={featuredImage?.title || 'Tipstool Detail Image'}
            width="100%"
            objectFit="cover"
            borderRadius="12px"
          />
        </Flex>
      )}
    </Flex>
    <Flex width="100%" maxHeight="70%" position="absolute" bottom="50%" left="0">
      <Image alt="Dots SVG Image" image={{ file: { url: dotsImg } }} width="25%" />
    </Flex>
  </Flex>
);

export default HeroColumn;
