import {
  ContentfulAsset,
  ContentfulComponentForm,
  ContentfulComponentImage,
  ContentfulComponentVideo,
  ContentfulEntityCompany,
  ContentfulItemStatistic,
  ContentfulMetaSeo,
  ContentfulTemplateCaseStudyBody,
  ContentfulTemplatePodcastBody,
  ContentfulTemplateResourceBody,
  Maybe,
  StringQueryOperatorInput,
} from 'graphqlTypes';
import { FC } from 'react';

import dotsImg from 'assets/images/dots.svg';
import { contentfulProps } from 'assets/mockData/emptyTypes';

import color from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image, { ImageData } from 'quarks/Image';
import Text from 'quarks/Text';

import AushaPlayer from 'components/AushaPlayer/AushaPlayer';
import Breadcrumbs, { BreadcrumbsProps } from 'components/Breadcrumbs/Breadcrumbs';
import ComponentButton from 'components/Button/ComponentButton';
import ComponentStatisticPanel from 'components/StatisticPanel/StatisticPanel';
import ComponentVideo from 'components/Video/Video';

import { formatDate } from 'utils/functions';
import timeToRead from 'utils/timeToRead';

type HeroRowProps = BreadcrumbsProps & {
  title?: string | null;
  featuredImage?: ContentfulAsset | null;
  featuredContent?: ContentfulComponentImage | ContentfulComponentVideo | null;
  pdf?: ContentfulAsset | null;
  cid?: string | null;
  category?: StringQueryOperatorInput | string | null;
  publishDate?: Date | null;
  body?: ContentfulTemplatePodcastBody | ContentfulTemplateResourceBody | ContentfulTemplateCaseStudyBody | null;
  seo?: ContentfulMetaSeo | null;
  statistics?: Maybe<Array<Maybe<ContentfulItemStatistic>>>;
  company?: ContentfulEntityCompany | null;
  aushaEpisodeId?: string | null;
};

const HeroRow: FC<HeroRowProps> = ({
  body,
  breadcrumbs,
  category,
  cid,
  company,
  displayBreadcrumbs,
  featuredImage,
  featuredContent,
  pdf,
  aushaEpisodeId,
  publishDate,
  seo,
  statistics,
  title,
}) => (
  <Flex
    position="relative"
    flexDirection="column"
    paddingTop={0}
    paddingBottom={64}
    sm={{ paddingTop: 48 }}
    backgroundColor="navy-900"
    css={
      aushaEpisodeId
        ? `&:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20%;
            background-color: ${color.common.white};
          }`
        : ''
    }
  >
    <Flex
      width="100%"
      maxWidth="1320px"
      marginX="auto"
      paddingX={20}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      gap="56px"
      position="relative"
      zIndex="1"
      md={{ gap: '64px' }}
    >
      {displayBreadcrumbs !== false && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Flex
        width="100%"
        flexDirection="column"
        gap="56px"
        justifyContent="space-between"
        alignItems="center"
        lg={{ flexDirection: 'row' }}
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          width="100%"
          maxWidth="100%"
          gap="24px"
          lg={{ maxWidth: '470px' }}
        >
          {aushaEpisodeId && (
            <Flex
              paddingX={12}
              paddingY={4}
              borderRadius="16px"
              backgroundColor="navy-50"
              fontSize="textXs"
              lineHeight="textXs"
              textColor="navy-700"
              width="fit-content"
              fontWeight="semiBold"
            >
              Podcast
            </Flex>
          )}
          {category && (
            <Text
              borderRadius="16px"
              paddingY={4}
              paddingX={12}
              textStyle="textXs"
              fontWeight="semiBold"
              textColor="green-900"
              textTransform="capitalize"
              width="fit-content"
              backgroundColor="green-50"
              border="1px solid"
              borderColor="gray-200"
            >
              {category}
            </Text>
          )}
          {title && (
            <Heading
              as="h1"
              fontSize="h1"
              lineHeight="h1"
              textColor="common-white"
              fontFamily="secondaryFont"
              md={{ fontSize: 'h1' }}
            >
              {title}
            </Heading>
          )}
          {publishDate && body && (
            <Flex
              width="100%"
              marginTop={8}
              gap="16px"
              alignItems="normal"
              sm={{ gap: '32px', maxWidth: '500px', alignItems: 'center' }}
            >
              {publishDate && (
                <Flex gap="16px" alignItems="center">
                  <Text textStyle="textXs" textColor="gray-50" fontWeight="regular">
                    {formatDate(publishDate, true)}
                  </Text>
                </Flex>
              )}

              <Text fontWeight="regular" textColor="gray-50" textStyle="textXs">
                {body ? timeToRead(body) : 0} min read
              </Text>
            </Flex>
          )}
          {pdf && (
            <ComponentButton
              label="Download PDF"
              popup={
                {
                  __typename: 'ContentfulComponentForm',
                  formType: 'Download PDF',
                  heading: 'For insights, tips, and more valuable resources, please complete this form.',
                  assetData: pdf,
                  hubspotForm: { guid: '8a197c87-a24d-4485-8751-0292838c3e7e' },
                  cid,
                } as unknown as ContentfulComponentForm
              }
            />
          )}
        </Flex>
        {(featuredContent || featuredImage || seo?.openGraphImage) && (
          <Flex position="relative" width="100%" maxWidth="100%" lg={{ maxWidth: '670px' }}>
            {featuredContent?.__typename === 'ContentfulComponentVideo' ? (
              <ComponentVideo {...featuredContent} />
            ) : (
              <Image
                image={
                  ((featuredContent?.bynderImageDesktop || featuredContent?.imageDesktop) as ImageData) ||
                  (featuredImage as ImageData) ||
                  (seo?.openGraphImage as ImageData)
                }
                alt={title || 'CaseStudy Detail Image'}
                width="100%"
                objectFit="cover"
                borderRadius="14px"
              />
            )}
            {company?.logo && (
              <Flex
                position="absolute"
                bottom="16px"
                left="16px"
                width="100%"
                maxWidth="100px"
                md={{ bottom: '24px', left: '24px', maxWidth: '180px' }}
              >
                <Image
                  image={company?.logo as ImageData}
                  alt={company?.company || 'CaseStudy Company Image'}
                  width="100%"
                  objectFit="cover"
                  borderRadius="10px"
                />
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
      {statistics && (
        <Flex width="100%">
          <ComponentStatisticPanel backgroundIsDark={true} statistic={statistics} {...contentfulProps} />
        </Flex>
      )}
      {aushaEpisodeId && <AushaPlayer episodeId={aushaEpisodeId} />}
    </Flex>
    <Flex width="100%" maxHeight="70%" position="absolute" top="50%" left="0">
      <Image alt="Dots SVG Image" image={{ file: { url: dotsImg } }} width="25%" />
    </Flex>
  </Flex>
);

export default HeroRow;
